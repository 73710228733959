/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Spinner
} from "reactstrap";

// Firestore
import { firestore } from "../../../../firebase";

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

// Auth actions
import {
  SET_CUSTOMERS,
  SET_CUSTOMERS_DEMOGRAPHICS,
  SET_CUSTOMERS_AFFINITIES,
  SET_RICH_INSIGHTS
} from 'store/actions';

/*
 *  Settings
 */

// Get last 7 days
let dates = [];
let values = [];
for (let i = 0; i < 7; i++) {
    // Setup default
    dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    values.push(0);
}

// Persona break down
let personaDataDetails = {
  // KPI's
  matches: {},
  conversionRate: {},
  lifetimeValue: {},
  revenueLift: {},

  // Demographics
  totalMaleCustomers: {},
  totalFemaleCustomers: {}
};

// Chart data placeholders
let chartData = {
  matches: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Matched Customers",
            data: values,
          }
        ]
      };
    }
  },
  conversionRate: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Conversion Rate",
            data: values,
          }
        ]
      };
    }
  },
  lifetimeValue: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Lifetime Value",
            data: values,
          }
        ]
      };
    }
  },
  revenueLift: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Projected Revenue Lift",
            data: values,
          }
        ]
      };
    }
  }
};

// Query: Customer Activity
async function getCustomerActivity(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;
  console.log("Get Customer Activity: ", startDate, endDate);

  // Get all current customers
  let customerActivityQuery = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
      {
          "id": "Customer Activity",
          "math": "hogql",
          "name": "Customer Activity",
          "type": "events",
          "order": 0,
          "math_hogql": "count(toFloat(properties.customer.metrics.conversion))",
          "properties": [
            {
                "key": "type",
                "type": "event",
                "value": [
                    "Customer Visit"
                ],
                "operator": "exact"
            },
            {
                "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
                "type": "hogql",
                "value": null
            },
            {
                "key": "properties.customer.version = '1.0.0'",
                "type": "hogql",
                "value": null
            },
            {
                "key": "clientId",
                "type": "event",
                "value": [
                    `${account.active.id}`
                ],
                "operator": "exact"
            }
          ],
          "custom_name": "Total Visitors"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 1,
        "math_hogql": "sum(toFloat(properties.customer.metrics.conversion))",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Total Conversions"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 2,
        "math_hogql": "avg(toFloat(properties.customer.metrics.lifetime_value))",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          // {
          //   "key": "properties.customer.metrics.conversion",
          //   "type": "hogql",
          //   "value": 1,
          //   "operator": "exact"
          // },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Lifetime Value"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 3,
        "math_hogql": "sum(toFloat(properties.customer.metrics.revenue_lift))",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Revenue Lift"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "math_hogql": "sum(toFloat(properties.customer.details.age))",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Customer Age - Sum"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "math_hogql": "count(properties.customer.details.age)",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Customer Age - Total"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "math_hogql": "count(properties.customer.details.gender)",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.details.gender = 'Male'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Total Male Customers"
      },
      {
        "id": "Customer Activity",
        "math": "hogql",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "math_hogql": "count(properties.customer.details.gender)",
        "properties": [
          {
              "key": "type",
              "type": "event",
              "value": [
                  "Customer Visit"
              ],
              "operator": "exact"
          },
          {
              "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}'`,
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.version = '1.0.0'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "properties.customer.details.gender = 'Female'",
              "type": "hogql",
              "value": null
          },
          {
              "key": "clientId",
              "type": "event",
              "value": [
                  `${account.active.id}`
              ],
              "operator": "exact"
          }
        ],
        "custom_name": "Total Female Customers"
      }
    ],
    "interval": "week",
    "breakdown": "properties.persona.name",
    "entity_type": "events",
    "breakdown_type": "hogql"
    //"refresh": (moment().minutes() < 1) // Refresh if before 1 minutes of new hour
  };

  // Execute query
  const customerActivityResponse = await axios.post(eventsApiUrl, customerActivityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Split results
  let totalVisitors = [];
  let totalConversions = [];
  let lifetimeValue = [];
  let revenueLift = [];
  let sumAge = [];
  let totalAge = [];
  let totalMaleCustomers = [];
  let totalFemaleCustomers = [];
  for (var i = 0; i < customerActivityResponse.data.result.length; i++) {
    // Grab value
    let customerPersonaStat = customerActivityResponse.data.result[i];

    // Check: Total Visitors
    if(customerPersonaStat.action.custom_name == "Total Visitors") {
      totalVisitors.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Total Conversions
    if(customerPersonaStat.action.custom_name == "Total Conversions") {
      totalConversions.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Lifetime Value
    if(customerPersonaStat.action.custom_name == "Lifetime Value") {
      lifetimeValue.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Revenue Lift
    if(customerPersonaStat.action.custom_name == "Revenue Lift") {
      revenueLift.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Age - Sum
    if(customerPersonaStat.action.custom_name == "Customer Age - Sum") {
      console.log(customerPersonaStat);
      sumAge.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Age - Total
    if(customerPersonaStat.action.custom_name == "Customer Age - Total") {
      console.log(customerPersonaStat);
      totalAge.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Gender - Total Male
    if(customerPersonaStat.action.custom_name == "Total Male Customers") {
      totalMaleCustomers.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }

    // Check: Gender - Total Female
    if(customerPersonaStat.action.custom_name == "Total Female Customers") {
      totalFemaleCustomers.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }
  }

  // Return values
  return {
    personas: customerActivityResponse.data.result,
    totalVisitors: totalVisitors,
    totalConversions: totalConversions,
    lifetimeValue: lifetimeValue,
    revenueLift: revenueLift,
    sumAge: sumAge,
    totalAge: totalAge,
    totalMaleCustomers: totalMaleCustomers,
    totalFemaleCustomers: totalFemaleCustomers
  };
}

// Query: Affinities (by Persona)
async function getAffinities(account, activeChart, personaName, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/query`;

  // Select Correct Weighted Score
  let weightedScoreExpression;
  switch (activeChart) {
      case 'lifetimeValue':
          weightedScoreExpression = `normalized_ltv_score`;
          break;
          
      case 'conversionRate':
          weightedScoreExpression = `weighted_conversion_score`;
          break;
          
      case 'projectedRevenueGrowth':
          weightedScoreExpression = `normalized_revenue_growth_score`;
          break;

      default:
          weightedScoreExpression = `normalized_ltv_score`; // Predidcted LTV as Default
  }

  // Select Target Metric
  let targetMetricExpression;
  switch (activeChart) {
      case 'lifetimeValue':
          targetMetricExpression = `avg_lifetime_value`;
          break;
          
      case 'conversionRate':
          targetMetricExpression = `conversion_rate`;
          break;
          
      case 'projectedRevenueGrowth':
          targetMetricExpression = `avg_revenue_growth`;
          break;

      default:
          targetMetricExpression = `avg_lifetime_value`; // Predidcted LTV as Default
  }

  // Get custoemr affinities
  let customerAffinityQuery = { 
    "query": {
      "kind": "HogQLQuery",
      "query": `
        WITH
          filtered_events AS (
              SELECT
                distinct_id AS customer_document_id,
                properties.persona.name AS persona_name,
                properties.affinity.type AS affinity_type,
                properties.affinity.name AS affinity_name,
                properties.affinity.data.product_type AS product_type,
                toFloat(properties.customer.metrics.conversion) AS conversion,
                toFloat(properties.customer.metrics.lifetime_value) AS predicted_lifetime_value,
                toFloat(properties.customer.metrics.revenue_lift) AS projected_revenue_growth
              FROM events AS e
              WHERE (team_id = ${account.config.events.project}) 
              AND (event = 'Customer Activity') 
              AND (properties.clientId = '${account.active.id}')
              AND (properties.type = 'Customer Affinity')
              AND (properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}')
              AND (properties.affinity.version = '1.0.0')
              AND (toTimeZone(timestamp, 'UTC') >= toDateTime('${startDate} 00:00:00', 'UTC')) 
              AND (toTimeZone(timestamp, 'UTC') <= toDateTime('${endDate} 23:59:59', 'UTC'))
              ${(personaName && personaName != 'all') ? `AND (properties.persona.name = '${personaName}')` : ``}
          ),
          base_metrics AS (
            SELECT
                affinity_name,
                product_type,
                -- Conversion metrics
                100.0 * sum(conversion) / count(*) AS conversion_rate,
                count(*) AS total_visitors,
                sum(conversion) AS total_conversions,
                
                -- Lifetime value metrics
                sum(predicted_lifetime_value) / nullif(count(*), 0) as avg_lifetime_value,
                stddevPop(predicted_lifetime_value) as ltv_stddev,
                
                -- Revenue growth metrics
                sum(projected_revenue_growth) / nullif(count(*), 0) as avg_revenue_growth,
                stddevPop(projected_revenue_growth) as revenue_growth_stddev,

                -- Wilson score for conversion rate
                (((100.0 * sum(conversion) / count(*)) + 1.96 * 1.96 / (2 * count(*)) - 
                1.96 * sqrt((100.0 * sum(conversion) / count(*) * (1 - sum(conversion) / count(*)) + 1.96 * 1.96 / (4 * count(*))) / count(*))) /
                (1 + 1.96 * 1.96 / count(*))) AS weighted_conversion_score
            FROM filtered_events
            GROUP BY 1, 2
            HAVING total_visitors >= 10
          ),

          metrics AS (
            SELECT 
                *,
                -- Calculate confidence intervals using pre-computed aggregates
                avg_lifetime_value - (1.96 * ltv_stddev / sqrt(total_visitors)) as weighted_ltv_score,
                avg_revenue_growth - (1.96 * revenue_growth_stddev / sqrt(total_visitors)) as weighted_revenue_growth_score
            FROM base_metrics
          ),

          normalized_metrics AS (
              SELECT 
                  affinity_name,
                  product_type,
                  
                  -- Original metrics
                  avg_lifetime_value,
                  conversion_rate,
                  avg_revenue_growth,
                  
                  -- Normalized weighted scores (0-100 scale)
                  weighted_conversion_score,
                  
                  -- Min-Max scaling for LTV score
                  100 * (weighted_ltv_score - MIN(weighted_ltv_score) OVER()) / 
                  NULLIF((MAX(weighted_ltv_score) OVER() - MIN(weighted_ltv_score) OVER()), 0) as normalized_ltv_score,
                  
                  -- Min-Max scaling for revenue growth score
                  100 * (weighted_revenue_growth_score - MIN(weighted_revenue_growth_score) OVER()) / 
                  NULLIF((MAX(weighted_revenue_growth_score) OVER() - MIN(weighted_revenue_growth_score) OVER()), 0) as normalized_revenue_growth_score,
                  
                  -- Combined weighted score (equal weights)
                  (weighted_conversion_score + 
                  100 * (weighted_ltv_score - MIN(weighted_ltv_score) OVER()) / 
                        NULLIF((MAX(weighted_ltv_score) OVER() - MIN(weighted_ltv_score) OVER()), 0) +
                  100 * (weighted_revenue_growth_score - MIN(weighted_revenue_growth_score) OVER()) / 
                        NULLIF((MAX(weighted_revenue_growth_score) OVER() - MIN(weighted_revenue_growth_score) OVER()), 0)
                  ) / 3 as combined_score
                  
              FROM metrics
          )

        SELECT 
            -- Affinity Details
            product_type,
            affinity_name,

            -- Weighted Scoring
            round(${weightedScoreExpression}, 2) AS weighted_score,

             -- Core Metric
            round(${targetMetricExpression}, 2) AS target_metric
        FROM normalized_metrics
        ORDER BY ${weightedScoreExpression} DESC
        LIMIT 5
      `
    }
  }

  // Execute query
  const customerAffinityResponse = await axios.post(eventsApiUrl, customerAffinityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Return values
  return {
    affinities: customerAffinityResponse.data.results
  };
}

function PersonasChart({ knowledge, account, dispatch }) {

  // Default states
  const [ loading, setLoading ] = React.useState(true);
  const [ activeNav, setActiveNav ] = React.useState(2);
  const [ personaData, setPersonaData ] = React.useState([]);
  const [ activeChart, setActiveChart ] = React.useState("lifetimeValue");
  const [ activeChartData, setActiveChartData ] = React.useState("default");

  // Toggle 
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setActiveChartData(activeChartData === "30-day" ? "90-day" : "30-day");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // // Get history
  // const history = useHistory();

  // Set KPI values
  const [ conversionRate, setConversionRate ] = React.useState(0.0);
  const [ predictedLifetimeValue, setPredictedLifetimeValue ] = React.useState(0.0);
  const [ projectedRevenueGrowth, setProjectedRevenueGrowth ] = React.useState(0.0);

  // Set macro values
  const [ conversionRateAll, setConversionRateAll ] = React.useState(0.0);
  const [ predictedLifetimeValueAll, setPredictedLifetimeValueAll ] = React.useState(0.0);
  const [ projectedRevenueGrowthAll, setProjectedRevenueGrowthAll ] = React.useState(0.0);

  // Load persona data
  React.useEffect(() => {
    if (account.initialized && account.active) {
      // Customer Activity
      (async () => {
        // Calcualte target dates based off selection
        const lookback = (activeChartData === "30-day" || activeChartData === "default") ? 30 : 90;
        const startDate = moment().subtract(lookback, 'days').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');

        // Get customer details
        const customerActivity = await getCustomerActivity(account, startDate, endDate);
        console.log("Persona Customer Activity: ", customerActivity);

        /*
         *  [1] Update Chart: Conversion Rate
         */
        let totalVisitorsData = [];
        let totalConversionsData = [];
        let totalVisitorsLabels = [];
        let conversionRateData = [];
        for(var i = 0; i < customerActivity.totalVisitors.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < customerActivity.totalVisitors[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              totalVisitorsData[j] += customerActivity.totalVisitors[i].data[j];
              totalConversionsData[j] += customerActivity.totalConversions[i].data[j];
            } else {
              // Set defaults
              totalVisitorsData = customerActivity.totalVisitors[i].data;
              totalConversionsData = customerActivity.totalConversions[i].data;
              totalVisitorsLabels = customerActivity.totalVisitors[i].days;
            }
          }

          // Update persona stats
          if(customerActivity.totalVisitors[i].label !== "(None)") {
            // Update data details
            personaDataDetails['conversionRate'][customerActivity.totalVisitors[i].label] = {
              name: customerActivity.totalVisitors[i].label,
              total: 100.0 * _.sum(customerActivity.totalConversions[i].data) / _.sum(customerActivity.totalVisitors[i].data)
            };

            // Add to converion rate data
            conversionRateData.push(100.0 * _.sum(customerActivity.totalConversions[i].data) / _.sum(customerActivity.totalVisitors[i].data));
          }

          // Update chart
          let personaConversionRateData = _.zipWith(customerActivity.totalConversions[i].data, customerActivity.totalVisitors[i].data, (a, b) => 100.0 * a / b)
          chartData.conversionRate[customerActivity.totalVisitors[i].label] = (canvas) => {
            return {
              labels: totalVisitorsLabels,
              datasets: [{
                label: "Conversion Rate",
                data: personaConversionRateData
              }]
            };
          };
        }

        // Update chart
        chartData.conversionRate['all'] = (canvas) => {
          return {
            labels: totalVisitorsLabels,
            datasets: [{
              label: "Conversion Rate",
              data: _.zipWith(totalConversionsData, totalVisitorsData, (a, b) => 100.0 * a / b)
            }]
          };
        };

        // Update stats
        setConversionRate(100.0 * _.sum(totalConversionsData) / _.sum(totalVisitorsData));
        setConversionRateAll(100.0 * _.sum(totalConversionsData) / _.sum(totalVisitorsData));

        // Update persona data
        console.log("Persona Data Details: ", personaDataDetails, conversionRateData);
        for(const personaName in personaDataDetails['conversionRate']) {
          // Update total
          personaDataDetails['conversionRate'][personaName]['strength'] = 100.0 * personaDataDetails['conversionRate'][personaName]['total'] / _.max(conversionRateData);
        }

        /*
         *  [2] Update Chart: Lifetime Value
         */
        let lifetimeValueData = [];
        let lifetimeValueLabels = [];
        for(var i = 0; i < customerActivity.lifetimeValue.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < customerActivity.lifetimeValue[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              lifetimeValueData[j] += customerActivity.lifetimeValue[i].data[j];
            } else {
              // Set defaults
              lifetimeValueData = customerActivity.lifetimeValue[i].data;
              lifetimeValueLabels = customerActivity.lifetimeValue[i].days;
            }
          }

          // Update persona stats
          if(customerActivity.lifetimeValue[i].label !== "(None)") {
            // Update data details
            personaDataDetails['lifetimeValue'][customerActivity.lifetimeValue[i].label] = {
              name: customerActivity.lifetimeValue[i].label,
              total: _.sum(customerActivity.lifetimeValue[i].data) / customerActivity.lifetimeValue[i].data.length
            };
          }

          // Update chart
          let personaLifetimeValueData = customerActivity.lifetimeValue[i].data;
          chartData.lifetimeValue[customerActivity.lifetimeValue[i].label] = (canvas) => {
            return {
              labels: lifetimeValueLabels,
              datasets: [{
                label: "Lifetime Value",
                data: personaLifetimeValueData
              }]
            };
          };
        }

        // Average results
        for(var i = 0; i < lifetimeValueData.length; i++) {
          // Average
          lifetimeValueData[i] /= customerActivity.lifetimeValue.length;
        }

        // Update chart
        chartData.lifetimeValue['all'] = (canvas) => {
          return {
            labels: lifetimeValueLabels,
            datasets: [{
              label: "Lifetime Value",
              data: lifetimeValueData
            }]
          };
        };

        // Update stats
        setPredictedLifetimeValue(_.sum(lifetimeValueData) / lifetimeValueData.length);
        setPredictedLifetimeValueAll(_.sum(lifetimeValueData) / lifetimeValueData.length);

        // Update persona data
        for(const personaName in personaDataDetails['lifetimeValue']) {
          // Update total
          personaDataDetails['lifetimeValue'][personaName]['strength'] = 100.0 * personaDataDetails['lifetimeValue'][personaName]['total'] / (_.max(lifetimeValueData));
        }

        /*
         *  [3] Update Chart: Revenue Lift
         */
        let revenueLiftData = [];
        let revenueLiftLabels = [];
        for(var i = 0; i < customerActivity.revenueLift.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < customerActivity.revenueLift[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              revenueLiftData[j] += customerActivity.revenueLift[i].data[j];
            } else {
              // Set defaults
              revenueLiftData = customerActivity.revenueLift[i].data;
              revenueLiftLabels = customerActivity.revenueLift[i].days;
            }
          }

          // Update persona stats
          if(customerActivity.revenueLift[i].label !== "(None)") {
            // Update data details
            personaDataDetails['revenueLift'][customerActivity.revenueLift[i].label] = {
              name: customerActivity.revenueLift[i].label,
              total: _.sum(customerActivity.revenueLift[i].data)
            };
          }

          // Update chart
          let personaRevenueLiftData = customerActivity.revenueLift[i].data;
          chartData.revenueLift[customerActivity.revenueLift[i].label] = (canvas) => {
            return {
              labels: revenueLiftLabels,
              datasets: [{
                label: "Revenue Lift",
                data: personaRevenueLiftData
              }]
            };
          };
        }

        // Update chart
        chartData.revenueLift['all'] = (canvas) => {
          return {
            labels: revenueLiftLabels,
            datasets: [{
              label: "Revenue Lift",
              data: revenueLiftData
            }]
          };
        };

        // Update stats
        setProjectedRevenueGrowth(_.sum(revenueLiftData));
        setProjectedRevenueGrowthAll(_.sum(revenueLiftData));

        // Update persona data
        for(const personaName in personaDataDetails['revenueLift']) {
          // Update total
          personaDataDetails['revenueLift'][personaName]['strength'] = 100.0 * personaDataDetails['revenueLift'][personaName]['total'] / (_.max(revenueLiftData));
        }

        /*
         *  [4.a] Demographics: Age
         */

        // // Male Customers
        // let sumAgeData = [];
        // for(var i = 0; i < customerActivity.sumAge.length; i ++) {
        //   // Iterate over all data elements
        //   for(var j = 0; j < customerActivity.sumAge[i].data.length; j++) {
        //     // Set defaults
        //     if(i > 0) {
        //       // Increment
        //       genderMaleData[j] += customerActivity.totalMaleCustomers[i].data[j];
        //     } else {
        //       // Set defaults
        //       genderMaleData = customerActivity.totalMaleCustomers[i].data;
        //     }
        //   }

        //   // Update persona stats
        //   if(customerActivity.totalMaleCustomers[i].label !== "(None)") {
        //     // Update data details
        //     personaDataDetails['totalMaleCustomers'][customerActivity.totalMaleCustomers[i].label] = {
        //       name: customerActivity.totalMaleCustomers[i].label,
        //       total: _.sum(customerActivity.totalMaleCustomers[i].data)
        //     };
        //   }
        // }

        /*
         *  [4.b] Demographics: Gender
         */

        // Male Customers
        let genderMaleData = [];
        for(var i = 0; i < customerActivity.totalMaleCustomers.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < customerActivity.totalMaleCustomers[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              genderMaleData[j] += customerActivity.totalMaleCustomers[i].data[j];
            } else {
              // Set defaults
              genderMaleData = customerActivity.totalMaleCustomers[i].data;
            }
          }

          // Update persona stats
          if(customerActivity.totalMaleCustomers[i].label !== "(None)") {
            // Update data details
            personaDataDetails['totalMaleCustomers'][customerActivity.totalMaleCustomers[i].label] = {
              name: customerActivity.totalMaleCustomers[i].label,
              total: _.sum(customerActivity.totalMaleCustomers[i].data)
            };
          }
        }

        // Female Customers
        let genderFemaleData = [];
        for(var i = 0; i < customerActivity.totalFemaleCustomers.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < customerActivity.totalFemaleCustomers[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              genderFemaleData[j] += customerActivity.totalFemaleCustomers[i].data[j];
            } else {
              // Set defaults
              genderFemaleData = customerActivity.totalFemaleCustomers[i].data;
            }
          }

          // Update persona stats
          if(customerActivity.totalFemaleCustomers[i].label !== "(None)") {
            // Update data details
            personaDataDetails['totalFemaleCustomers'][customerActivity.totalFemaleCustomers[i].label] = {
              name: customerActivity.totalFemaleCustomers[i].label,
              total: _.sum(customerActivity.totalFemaleCustomers[i].data)
            };
          }
        }

        // Set iniital demomgraphics
        console.log("Demographics (Gender): ", _.sum(genderMaleData), _.sum(genderFemaleData));
        dispatch({
          type: SET_CUSTOMERS_DEMOGRAPHICS,
          payload: {
            age: 35.0,
            gender: {
              male: 100.0 * _.sum(genderMaleData) / (_.sum(genderMaleData) + _.sum(genderFemaleData)),
              female: 100.0 * _.sum(genderFemaleData) / (_.sum(genderMaleData) + _.sum(genderFemaleData)),
            }
          }
        });
        
        // Set chart
        console.log("Chart Data: ", chartData);
        setActiveChartData('all');

        // Set persona data
        let updatedPersonaData = [];
        for(const personaName in personaDataDetails[activeChart]) {
          // Update total
          updatedPersonaData.push(personaDataDetails[activeChart][personaName])
        }
        setPersonaData(updatedPersonaData);

        // Update loading
        setLoading(false);

        /*
         *  [4] Populate Initial Customer Personas
         */

        // Get initial customer affinities
        const customerAffinities = await getAffinities(account, activeChart, null, startDate, endDate);
        console.log("Customer Affinities: ", customerAffinities);

        // Format affinities
        let customerAffinityData = [];
        for(var i = 0; i < customerAffinities.affinities.length; i ++) {
          // Add data
          customerAffinityData.push({
            type: customerAffinities.affinities[i][0],
            display: customerAffinities.affinities[i][1],
            value: customerAffinities.affinities[i][2],
            metric: customerAffinities.affinities[i][3]
          });
        }

        // Dispatch customer affinities
        dispatch({
          type: SET_CUSTOMERS_AFFINITIES,
          payload: customerAffinityData
        });

        // // Populate customer details
        // const customerDetails = await getCustomers(account, null, startDate, endDate);
        // const customerIDs = _.map(customerDetails.customers, function(x) { return x[0]; });
        // console.log("Customer Personas (List): ", customerDetails.customers);

        // // If customer entries exist
        // if(customerIDs.length > 0) { 
        //   // Load mapped customer data
        //   let querySnapshot = await firestore.collection("customers_v0.9").where('id', 'in', customerIDs).get();
        //   let customers = [];
        //   querySnapshot.forEach((doc) => {
        //     customers.push(doc.data());
        //   });

        //   // Initialize insights
        //   dispatch({
        //     type: SET_CUSTOMERS,
        //     payload: customers.slice(0, 10)
        //   });
        // }
      })();
    }
  }, [ account.active ]);

  // Handle chart click
  const selectChart = async (chartName) => {
    // Update chart
    setActiveChart(chartName);

    // Set persona data
    let updatedPersonaData = [];
    for(const personaName in personaDataDetails[chartName]) {
      // Update total
      updatedPersonaData.push(personaDataDetails[chartName][personaName])
    }
    setPersonaData(updatedPersonaData);
    console.log(" - Updated Persona Data: ", updatedPersonaData, chartName, personaDataDetails);

    // Update KPI's
    //setConversionRate((activeChartData == 'all') ? conversionRateAll : personaDataDetails['conversionRate'][activeChartData]['total']);
    //setPredictedLifetimeValue((activeChartData == 'all') ? predictedLifetimeValueAll : personaDataDetails['lifetimeValue'][activeChartData]['total']);
    // setRevenueLift((activeChartData == 'all') ? revenueLiftAll : personaDataDetails['revenueLift'][activeChartData]['total']);
  }

  // Handle chart click
  const selectPersona = async (selectedPersonaName, personas) => {
    console.log("Selected Persona Data: ", personaDataDetails)

    // Update chart
    setActiveChartData((selectedPersonaName == activeChartData) ? 'all' : selectedPersonaName);

    // Set persona data
    let updatedPersonaData = [];
    for(const personaName in personaDataDetails[activeChart]) {
      // Update total
      updatedPersonaData.push(personaDataDetails[activeChart][personaName])
    }
    setPersonaData(updatedPersonaData);
    console.log(" - Updated Persona Data: ", updatedPersonaData, activeChart, personaDataDetails);

    // Update KPI's
    setConversionRate((selectedPersonaName == activeChartData) ? conversionRateAll : personaDataDetails['conversionRate'][selectedPersonaName]['total']);
    setPredictedLifetimeValue((selectedPersonaName == activeChartData) ? predictedLifetimeValueAll : personaDataDetails['lifetimeValue'][selectedPersonaName]['total']);
    // setRevenueLift((selectedPersonaName == activeChartData) ? revenueLiftAll : personaDataDetails['revenueLift'][selectedPersonaName]['total']);

    /*
     *  Update Customer Affinities
     */

    // Calcualte target dates based off selection
    const lookback = (activeChartData === "30-day" || activeChartData === "default") ? 30 : 90;
    const startDate = moment().subtract(lookback, 'days').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    // Clear customer affinities
    dispatch({
      type: SET_CUSTOMERS_AFFINITIES,
      payload: []
    });

    // Get initial customer affinities
    let customerAffinities = await getAffinities(account, activeChart, selectedPersonaName, startDate, endDate);
    console.log("Customer Affinities (Selected): ", customerAffinities);

    // Format affinities
    let customerAffinityData = [];
    for(var i = 0; i < customerAffinities.affinities.length; i ++) {
      // Add data
      customerAffinityData.push({
        type: customerAffinities.affinities[i][0],
        display: customerAffinities.affinities[i][1],
        value: customerAffinities.affinities[i][2],
        metric: customerAffinities.affinities[i][3]
      });
    }

    // Dispatch customer affinities
    dispatch({
      type: SET_CUSTOMERS_AFFINITIES,
      payload: customerAffinityData
    });

    // Update Rich Insights
    console.log(" - Setting Rich Insights: ", selectedPersonaName, personas);
    if(selectedPersonaName != activeChartData) {
      const selectedPersona = personas.find(p => p.name === selectedPersonaName);
      if (selectedPersona && selectedPersona.insights && selectedPersona.insights.product_affinities) {
        dispatch({
          type: SET_RICH_INSIGHTS,
          payload: selectedPersona.insights.product_affinities
        });
      }
    } else { 
      // TODO -- load the cross persona insights
    }
  }

  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Personas
                </h6>
                <h5 className="h3 text-white mb-0">New Customers</h5>
              </div>
              <div className="col">
                <Nav className="justify-content-end" pills>
                  <NavItem className="mr-2 mr-md-0">
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: activeNav === 1,
                      })}
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 2)}
                    >
                      <span className="d-none d-md-block">30 Days</span>
                      <span className="d-md-none">M</span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: activeNav === 2,
                      })}
                      data-toggle="tab"
                      href="#pablo"
                      // onClick={(e) => toggleNavs(e, 1)}
                      disabled
                    >
                      <span className="d-none d-md-block">90 Days</span>
                      <span className="d-md-none">W</span>
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'lifetimeValue' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('lifetimeValue')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'lifetimeValue' ? `text-primary` : 'text-white'}`}
                        >
                          Predicted Visitor Value
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'lifetimeValue' ? `` : 'text-white'}`}>{accounting.formatMoney(predictedLifetimeValue)}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'conversionRate' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('conversionRate')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'conversionRate' ? `text-primary` : 'text-white'}`}
                        >
                          True Conversion Rate
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'conversionRate' ? `` : 'text-white'}`}>{ (conversionRate).toFixed(2) }%</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'revenueLift' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('revenueLift')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'revenueLift' ? `text-primary` : 'text-white'}`}
                        >
                          Retained Revenue Lift
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'revenueLift' ? `` : 'text-white'}`}>{accounting.formatMoney(projectedRevenueGrowth)}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                { loading ? 
                  <Row style={{ marginTop: 100, flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Spinner color="white" /> 
                  </Row>
                  :
                  <ListGroup className="list my--3 bg-primary" flush>
                  { personaData.map((persona, index) => (
                    <ListGroupItem
                      className={`px-0 ${persona.name == activeChartData ? 'bg-white rounded px-2' : 'bg-primary border-0'}`}
                      onClick={() => selectPersona(persona.name, knowledge.entities)}
                      style={{ cursor: 'pointer' }}
                      key={index}
                    >
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className={`${persona.name == activeChartData ? '' : 'text-white'}`}>{persona.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color={persona.name == activeChartData ? 'primary' : 'info'}
                            max="100"
                            value={persona.strength}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                }
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Line
                      data={chartData[activeChart][activeChartData]}
                      options={chartOptions.options}
                      id="customer-activity"
                      className="chart-canvas"
                    />         
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedPersonasChart = connect(state => ({
  knowledge: state.knowledge,
  account: state.account
}))(PersonasChart);

export default ConnectedPersonasChart;
