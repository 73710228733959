import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spring, animated, config } from 'react-spring/renderprops';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  Button,
  Progress,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Spinner
} from "reactstrap";
import { Bar } from 'react-chartjs-2';
import accounting from 'accounting-js';
import PropTypes from 'prop-types';

// Custom typewriter hook
const useTypewriter = (text, speed = 30) => {
  const [displayText, setDisplayText] = React.useState('');
  const [isTyping, setIsTyping] = React.useState(true);

  React.useEffect(() => {
    let i = 0;
    setIsTyping(true);
    setDisplayText('');

    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayText(prev => prev + text.charAt(i));
        i++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
      }
    }, speed);

    return () => clearInterval(typingInterval);
  }, [text, speed]);

  return { displayText, isTyping };
};

// Typewriter component
const Typewriter = ({ text, speed = 30 }) => {
  const { displayText } = useTypewriter(text, speed);
  return <span>{displayText}</span>;
};

// Animated components wrapper
const AnimatedContent = ({ children, delay = 0, type = 'fade' }) => {
  const [key, setKey] = React.useState(0);
  
  React.useEffect(() => {
    setKey(prev => prev + 1);
  }, [children]);

  const animations = {
    fade: {
      from: { opacity: 0 },
      to: { opacity: 1 }
    },
    slideUp: {
      from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
      to: { opacity: 1, transform: 'translate3d(0,0px,0)' }
    },
    slideIn: {
      from: { opacity: 0, transform: 'translate3d(-40px,0,0)' },
      to: { opacity: 1, transform: 'translate3d(0px,0,0)' }
    },
    scale: {
      from: { opacity: 0, transform: 'scale(0.95)' },
      to: { opacity: 1, transform: 'scale(1)' }
    }
  };

  return (
    <Spring
      key={key}
      from={animations[type].from}
      to={animations[type].to}
      config={{ ...config.gentle, delay }}
    >
      {props => <animated.div style={props}>{children}</animated.div>}
    </Spring>
  );
};

const RichInsights = ({ richInsights }) => {
  const [activeTab, setActiveTab] = useState('analysis');
  const [showContent, setShowContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  // Reset animations and handle loading state when richInsights changes
  useEffect(() => {
    setIsLoading(true);
    setShowContent(false);
    
    // Small delay to ensure loading state is visible
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
      setShowContent(true);
    }, 100);
    
    return () => clearTimeout(loadingTimer);
  }, [richInsights]); // Trigger on richInsights changes

  if (isLoading) {
    return (
      <Card className="rich-insights">
        <CardBody className="text-center p-5">
          <Spinner color="primary" />
          <p className="mt-3">Loading insights...</p>
        </CardBody>
      </Card>
    );
  }

  if (!richInsights || !richInsights.content) {
    return (
      <Card className="rich-insights">
        <CardBody className="text-center p-5">
          <Spinner color="primary" />
          <p className="mt-3">Loading insights...</p>
        </CardBody>
      </Card>
    );
  }

  const { content } = richInsights;
  
  const renderChartInsights = (insights) => (
    <AnimatedContent delay={500} type="slideUp">
      <div className="mt-4 p-3 bg-light rounded">
        <h5 className="text-primary mb-3">{insights.title}</h5>
        <Typewriter text={insights.summary} speed={30} />
        <ul className="list-unstyled mt-3">
          {insights.bullets.map((bullet, index) => (
            <AnimatedContent key={index} delay={800 + (index * 200)} type="slideIn">
              <li className="mb-2">
                <i className="fas fa-circle text-primary mr-2" style={{fontSize: '8px'}}></i>
                {bullet}
              </li>
            </AnimatedContent>
          ))}
        </ul>
      </div>
    </AnimatedContent>
  );

  const renderVisualization = (visualization) => (
    <AnimatedContent delay={300} type="fade">
      <Row>
        <Col md="12">
          <div style={{ height: '280px' }}>
            <Bar 
              data={visualization.data}
              options={{
                ...content.chartDefaults.options,
                ...visualization.options,
                animation: {
                  duration: 2000,
                  easing: 'easeInOutQuart'
                }
              }}
            />
          </div>
          {renderChartInsights(visualization.insights)}
        </Col>
      </Row>
    </AnimatedContent>
  );

  return (
    <Spring
      from={{ opacity: 0, transform: 'translate3d(0,30px,0)' }}
      to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
      config={{ tension: 280, friction: 20 }}
    >
      {props => (
        <animated.div style={props}>
          <Card className="rich-insights">
            <CardHeader>
              <h3 className="mb-0">{content.header.title}</h3>
              <p className="text-muted mb-0">{content.header.subtitle}</p>
            </CardHeader>
            <CardBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 'analysis' ? 'active' : ''}
                    onClick={() => setActiveTab('analysis')}
                  >
                    AI Analysis
                  </NavLink>
                </NavItem>
                {content.visualizations.map(viz => (
                  <NavItem key={viz.id}>
                    <NavLink
                      className={activeTab === viz.id ? 'active' : ''}
                      onClick={() => setActiveTab(viz.id)}
                    >
                      {viz.title}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <div className="tab-content mt-4">
                {/* AI Analysis Tab */}
                <div className={`tab-pane ${activeTab === 'analysis' ? 'active' : ''}`}>
                  <Alert color="info">
                    <Typewriter text={content.primaryAnalysis.summary} speed={30} />
                  </Alert>

                  <AnimatedContent delay={1000} type="slideUp">
                    <Row>
                      <Col md="12">
                        <h4>Key Findings</h4>
                        <Row>
                          {content.primaryAnalysis.keyFindings.map((finding, index) => (
                            <Col md="6" key={index} className="mb-3">
                              <AnimatedContent delay={300 * (index + 1)} type="slideUp">
                                <div className="p-3 bg-light rounded">
                                  <Badge color="primary" className="mr-2">{index + 1}</Badge>
                                  {finding}
                                </div>
                              </AnimatedContent>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </AnimatedContent>

                  <AnimatedContent delay={1500} type="slideUp">
                    <Row>
                      <Col md="12">
                        <h4>Start a Conversation</h4>
                        <Row>
                          {content.primaryAnalysis.explorationTopics.map((topic, index) => (
                            <Col md="4" key={index}>
                              <AnimatedContent delay={500 * (index + 1)} type="scale">
                                <Button
                                  color="primary"
                                  outline
                                  block
                                  className="text-left mb-3 p-3"
                                  onClick={() => console.log(`Starting conversation about: ${topic.title}`, topic.context)}
                                >
                                  <h5 className="mb-2">{topic.title}</h5>
                                  <small className="text-muted">{topic.query}</small>
                                </Button>
                              </AnimatedContent>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </AnimatedContent>
                </div>

                {/* Dynamic Visualization Tabs */}
                {content.visualizations.map(visualization => (
                  <div 
                    key={visualization.id}
                    className={`tab-pane ${activeTab === visualization.id ? 'active' : ''}`}
                  >
                    {renderVisualization(visualization)}
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </animated.div>
      )}
    </Spring>
  );
};

// PropTypes definition remains the same
RichInsights.propTypes = {
  richInsights: PropTypes.shape({
    meta: PropTypes.shape({
      analysisType: PropTypes.string.isRequired,
      entityType: PropTypes.string.isRequired,
      entitySubtype: PropTypes.string,
      generatedAt: PropTypes.string.isRequired,
      dataRange: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    content: PropTypes.shape({
      header: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired
      }).isRequired,
      primaryAnalysis: PropTypes.shape({
        summary: PropTypes.string.isRequired,
        keyFindings: PropTypes.arrayOf(PropTypes.string).isRequired,
        explorationTopics: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          query: PropTypes.string.isRequired,
          context: PropTypes.object
        })).isRequired
      }).isRequired,
      visualizations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        insights: PropTypes.shape({
          title: PropTypes.string.isRequired,
          summary: PropTypes.string.isRequired,
          bullets: PropTypes.arrayOf(PropTypes.string).isRequired
        }).isRequired
      })).isRequired,
      chartDefaults: PropTypes.object.isRequired
    }).isRequired
  })
};

const mapStateToProps = (state) => ({
  richInsights: state.insights.richInsights
});

export default connect(mapStateToProps)(RichInsights);