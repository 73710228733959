/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React Imports
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  CardImg,
  Form,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
  Container,
  Spinner,
  Row,
  Col
} from "reactstrap";

// Formatting
import accounting from 'accounting-js';

// Firestore
import { firestore } from "./../../../firebase";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  CLEAR_MESSAGES,
  SET_ACTIVE_ENTITY,
  SET_ACTIVE_CUSTOMER,
  SET_PERSONA,
  SET_QUERY,
  SET_PERSONA_AFFINITIES
} from 'store/actions';

// Query: Affinities (by Persona)
async function getAffinities(account, personaName, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/query`;

  // Get custoemr affinities
  let customerAffinityQuery = { 
    "query": {
      "kind": "HogQLQuery",
      "query": `
        WITH
            filtered_events AS (
                SELECT
                  distinct_id AS customer_document_id,
                  properties.persona.name AS persona_name,
                  properties.affinity.type AS affinity_type,
                  properties.affinity.name AS affinity_name,
                  properties.affinity.data.product_type AS product_type,
                  toFloat(properties.customer.metrics.conversion) AS conversion
                FROM events AS e
                WHERE (team_id = ${account.config.events.project}) 
                AND (event = 'Customer Activity') 
                AND (properties.clientId = '${account.active.id}')
                AND (properties.type = 'Customer Affinity')
                AND (properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '1.0.0'}')
                AND (properties.affinity.version = '1.0.0')
                AND (toTimeZone(timestamp, 'UTC') >= toDateTime('${startDate} 00:00:00', 'UTC')) 
                AND (toTimeZone(timestamp, 'UTC') <= toDateTime('${endDate} 23:59:59', 'UTC'))
                ${(personaName && personaName != 'all') ? `AND (properties.persona.name = '${personaName}')` : ``}
            ),
            metrics AS (
              SELECT
                  affinity_name,
                  product_type,
                  100.0 * sum(conversion) / count(*) AS conversion_rate,
                  count(*) AS total_visitors,
                  sum(conversion) AS total_conversions,

                  -- Wilson score lower bound for 95% confidence
                  (((100.0 * sum(conversion) / count(*)) + 1.96 * 1.96 / (2 * count(*)) - 
                  1.96 * sqrt((100.0 * sum(conversion) / count(*) * (1 - sum(conversion) / count(*)) + 1.96 * 1.96 / (4 * count(*))) / count(*))) /
                  (1 + 1.96 * 1.96 / count(*))) AS weighted_score
              FROM filtered_events
              GROUP BY 1, 2
              HAVING total_visitors >= 10 -- Minimum sample size threshold
            )

        SELECT 
            affinity_name,
            product_type,
            round(conversion_rate, 2) AS conversion_rate,
            total_visitors,
            total_conversions,
            round(weighted_score, 2) AS weighted_score
        FROM metrics
        ORDER BY weighted_score DESC
        LIMIT 10
      `
    }
  }

  // Execute query
  const customerAffinityResponse = await axios.post(eventsApiUrl, customerAffinityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Return values
  return {
    affinities: customerAffinityResponse.data.results
  };
}

const ProfileCard = ({ persona }) => {
  console.log("Persona Profile Card: ", persona.personality.name.full);
  console.log(" - Persona: ", persona.personality);

  // State to manage the current view
  // const [currentView, setCurrentView] = useState('default');
  const [selectedTrait, setSelectedTrait] = useState(null);

  // Render method for different views
  const renderContent = () => {
    // Get current trait render type
    let renderType = (selectedTrait && selectedTrait.type) ? selectedTrait.type : 'default';
    console.log(" - Render Type", renderType);

    // Return based on current view
    switch(renderType) {
      case 'list':
        return (
          <Row>
            <Col md="12">
              <div className="h4 text-center mt-4 mb-3">
                {selectedTrait.name}
              </div>
              <div className="h5 text-justify font-weight-300">
                <ul>
                  {selectedTrait.data.map((item, idx) => (
                    <li key={idx}>{item}</li> 
                  ))}
                </ul>
              </div>
              <div className="text-center mt-4">
                <Button color="secondary" onClick={() => setSelectedTrait(null)}>
                  Back to Profile
                </Button>
              </div>
            </Col>
          </Row>
        );
      
      case 'text':
        return (
          <Row>
            <Col md="12">
              <div className="h4 text-center mt-4 mb-3">
                {selectedTrait.name}
              </div>
              <div className="h5 text-justify font-weight-300">
                {selectedTrait.data}
              </div>
              <div className="text-center mt-4">
                <Button color="secondary" onClick={() =>  setSelectedTrait(null)}>
                  Back to Profile
                </Button>
              </div>
            </Col>
          </Row>
        );
    
      default:
        return (
          <Row>
            <Col md="12">
              <blockquote className="blockquote text-center">
                <p className="mb-0 font-weight-500 align-middle">{persona.personality.quote}</p>
              </blockquote>
            </Col>
            <Col md="12"> 
              <div className="h4 mt-1 text-center">
                About {persona.personality.name.first}
              </div>
              <div className="h5 text-justify font-weight-300">
                {persona.personality.background}
              </div> 
            </Col>
          </Row>
        );
    }
  };

  return (
    <Col md="5">
      <Card className="card-profile" style={{ height: '100%' }}>
        <CardImg
          alt={persona.personality.name.full}
          src={persona.personality.media.video.url}
          top
        />
        <CardBody className="pt-0">
          <div className="text-center mt-3">
            <div className="h1">
              {persona.personality.name.full}
            </div>
          </div>
          
          {/* Dynamic Content Area */}
          {renderContent()}

          {/* Navigation Buttons */}
          {!selectedTrait && (
            <div className="mt-3 text-center">
              { persona.personality.traits.length > 0 && persona.personality.traits.map((trait, idx) => (
                <Button 
                  color="primary" 
                  size="sm"
                  onClick={() => setSelectedTrait(trait)}
                  className="mr-2 mb-2"
                  key={idx}
                >
                  {trait.name}
                </Button>
              )) }
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

function ViewPersona({ account, insights, report, model, dispatch }) {
  // Get context
  const { id } = useParams();
  console.log("Persona: ", model);

  // Get history
  const history = useHistory();

  // Active tabs
  const [activeTab, setActiveTab] = React.useState('purchase_criteria');

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  // States (Report)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const [activeNav, setActiveNav] = React.useState(1);
  const listRef = React.useRef(null);

  // Load persona
  useEffect(() => {
    // Initialzie entity
    if (account.initialized && id) {
      console.log("Loading Persona: ", id);

      // Check if entity already loaded
      if(!model.entity) {
        // Load customer data
        firestore.collection("personas").doc(id).get()
          .then((doc) => {
            if(doc.exists) {
              // Update customer
              const personaData = doc.data();

              // Update local store
              dispatch({
                type: SET_ACTIVE_ENTITY,
                payload: personaData
              });
            }
          })
        } else {
          console.log(" --> Persona Exists: ", model.entity);

          // Update product affinities (Async)
          (async () => {
            // Calculate target dates based off selection
            const lookback = 180; // Last 6 months
            const startDate = moment().subtract(lookback, 'days').format('YYYY-MM-DD');
            const endDate = moment().format('YYYY-MM-DD');

            // Get initial customer affinities
            let customerAffinities = await getAffinities(account, model.entity.name, startDate, endDate);
            console.log("Customer Affinities (Selected): ", customerAffinities);

            // Format affinities
            let customerAffinityData = [];
            for(var i = 0; i < customerAffinities.affinities.length; i ++) {
              // Add data
              customerAffinityData.push({
                type: "Product",
                display: customerAffinities.affinities[i][0],
                value: customerAffinities.affinities[i][2]
              });
            }

            // Dispatch customer affinities
            dispatch({
              type: SET_PERSONA_AFFINITIES,
              payload: customerAffinityData
            });
          })();
        }
      }
  }, [ model.entity ]);

  // Handle customer selection
  const selectCustomer = async (customer) => {
    // Load customer data
    firestore.collection("customers").doc(customer.document_id).get()
      .then((doc) => {
        if(doc.exists) {
          // Update customer
          const customerData = doc.data();

          // Update local store
          dispatch({
            type: SET_ACTIVE_CUSTOMER,
            payload: customerData
          });

          // Got to review page
          console.log(customerData);
          history.push(`/admin/customers/insights/view/${customer.document_id}`);
        }
      })
  }

  return (
    <>
      <KnowledgeHeader
        headline={model.entity.name}
        description={model.entity.description}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card className="bg-primary">
              <CardBody>
                <Row>
                  { model.entity.personality && <ProfileCard persona={model.entity}/> }
                  <Col md={ model.entity.personality ? 7 : 12 }>
                    <Row>
                      <Col xl="12">
                        <div className="customer-profile" style={{
                          padding: '10px 10px 0px 10px'
                        }}>
                          <h2 className="text-white display-3" style={{ fontSize: 24}}>{model.entity.profile.summary.headline}</h2>
                          <p className="lead text-white" style={{ fontSize: 18}}>
                            {model.entity.profile.summary.details}
                          </p>
                          <hr/>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      { model.entity.profile.kpis.map((kpi, idx) => (
                        <Col xl="4">
                          <Card className={`card-stats text-white bg-white`}>
                            <CardBody>
                              <Row>
                                <div className="col">
                                  <CardTitle
                                    tag="h5"
                                    className={`text-uppercase text-muted mb-0 text-primary`}
                                  >
                                    { kpi.name }
                                  </CardTitle>
                                  <span className={`h2 font-weight-bold mb-0 ${(kpi.highlight) ? 'text-white' : ''}`}>{ kpi.value }</span>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="4">
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Key Demographics</span>
                </CardTitle>
                { model.entity.demographics.age ?
                  <Row>
                    <Col md="1"></Col>
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Age
                    </Label>
                    <Col md="6">
                      <Row className="col-form-label">
                        <div>{model.entity.demographics.age.toFixed(0)}</div>
                      </Row>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  :
                  <></>
                }
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Gender
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>{model.entity.demographics.gender.female.toFixed(0)}% ∙ Female</div>
                    </Row>
                    <Row className="col-form-label">
                      <div>{model.entity.demographics.gender.male.toFixed(0)}% ∙ Male</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                { model.entity.demographics.income != "" ?
                  <Row>
                    <Col md="1"></Col>
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Income
                    </Label>
                    <Col md="6">
                      <Row className="col-form-label">
                        <div>{model.entity.demographics.income}</div>
                      </Row>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  :
                  <></>
                }
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Form>
                  <Select2
                    className="form-control"
                    defaultValue="1"
                    options={{
                      placeholder: "Select",
                    }}
                    data={[
                      { id: "1", text: "Top Converting Products" }
                    ]}
                  />
                </Form>
              </CardHeader>
              { model.preferences && model.preferences.length === 0 ? 
                <CardBody  style={{
                  minHeight: 350
                }}>
                  <Row style={{ marginTop: 150, flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Spinner color="primary" /> 
                  </Row>
                </CardBody>
                :
                <CardBody  style={{
                  minHeight: 350
                }}>
                  <ListGroup className="list my--3" flush>
                    { model.preferences.map((concept, index) => (
                      <ListGroupItem className="px-0" key={index}>
                        <Row className="align-items-center">
                          <div className="col">
                            <h5>
                              <Badge color="primary" className="mr-2">
                                {concept.value.toFixed(2)}%
                              </Badge>
                              {concept.display}
                            </h5>
                            <Progress
                              className="progress-xs mb-0"
                              color="primary"
                              max="100"
                              value={concept.value}
                            />
                          </div>
                          <Col className="col-auto">
                            <Button
                              color="primary" size="sm" type="button"
                              style={{
                                width: 100
                              }}
                              onClick={() => {
                                // View customer activity drill-down
                                //history.push(`/admin/customers/insights?persona=${model.entity.name}&filter=${dimension.display}`);
                              }}
                              disabled
                            >
                              View
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              }
            </Card>
          </Col>
          <Col lg="8">
            <Card
              className="bg-gradient-primary border-0"
              style={{
                height: 140
              }}
            >
              <CardBody>
                <Row>
                  <Col md="12">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Ask this customer a question..."
                      style={{
                        zIndex: 100000000,
                        position: 'absolute',
                        width: '90%',
                        top: 5
                      }}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Set persona
                          dispatch({
                            type: SET_PERSONA,
                            payload: {
                              persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                            }
                          });

                          // Set initial query
                          if(query.length > 0) {
                            dispatch({
                              type: SET_QUERY,
                              payload: {
                                query: query
                              }
                            });
                          }

                          // Go to agent
                          history.push(`/admin/personas/chat`);
                        }
                      }}
                    />
                    <div
                      className="icon icon-shape bg-white text-dark rounded-circle shadow"
                      style={{
                        zIndex: 200000000,
                        position: 'absolute',
                        right: '2%',
                        top: -2,
                        height: 80,
                        width: 80,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        // Set persona
                        dispatch({
                          type: SET_PERSONA,
                          payload: {
                            persona: (model.entity && model.entity.persona) ? model.entity.persona : ""
                          }
                        });

                        // Navigate to agent
                        history.push(`/admin/knowledge/agent`);
                      }}
                    >
                      <i className="ni ni-books" />
                    </div>
                  </Col>
                  <Col
                    md="12"
                    style={{
                      paddingTop: 65
                    }}
                  >
                    <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                      purchase motivations
                    </Button>
                    <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                      competing priorties
                    </Button>
                    <Button onClick={() => { history.push(`/admin/knowledge/agent`); }} color="default" size="sm" type="button">
                      personal motivations
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader tag="h4" className="p-0 border-bottom-0">
                <Nav tabs fill>
                  <NavItem>
                    <NavLink active={activeTab=='purchase_criteria'} onClick={() => setActiveTab('purchase_criteria')}>
                      Purchase Criteria
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink active={activeTab=='match_criteria'} onClick={() => setActiveTab('match_criteria')}>
                      Match Criteria
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink active={activeTab=='matched_customers'} onClick={() => setActiveTab('matched_customers')}>
                      Matched Customers
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="purchase_criteria">
                    <Row>
                      <Col xl="12">
                        { model.entity.factors.map((factor, index) => (
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-3" tag="h3">
                                <Row>
                                  <Col xl="8">
                                    <h4 className="mb-0">
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        {factor.name}
                                      </a>
                                    </h4>
                                    <p className="text-sm text-muted mb-0">{factor.summary}</p>
                                  </Col>
                                  <Col xl="3">
                                    <Progress
                                      className="progress-xs mt-2 mb-0"
                                      color="primary"
                                      max="100"
                                      value={factor.value}
                                    />
                                  </Col>
                                  <Col xl="1">
                                    <div
                                      className="icon icon-shape bg-primary text-white rounded-circle shadow"
                                      style={{
                                        position: 'relative',
                                        right: 15,
                                        bottom: 10
                                      }}
                                    >
                                      <div style={{
                                        fontSize: 14
                                      }}>{factor.value}%</div>
                                    </div>
                                  </Col>
                                </Row>
                              </CardTitle>
                              <CardText className="mb-0">
                                {factor.details}
                              </CardText>
                              <CardFooter>
                                <Button
                                  color="primary" size="md" type="button"
                                  style={{
                                    width: 120,
                                    float: 'right'
                                  }}
                                  onClick={() => {
                                    // Create Persona context
                                    const persona = `You are a buying person with the following preferences:
                                      ** Persona Overview **  
                                      ${model.entity.description}

                                      Always speak in the first person, as if you were talking on behalf of this customer persona. Elaborate when possible, use examples, and be specific. This will help the AI model understand the context and provide more accurate responses.
                                    `;

                                    // Set persona
                                    console.log(" --> Persona: ", persona);
                                    dispatch({
                                      type: SET_PERSONA,
                                      payload: {
                                        persona: persona
                                      }
                                    });

                                    // Set initial message
                                    dispatch({
                                      type: CLEAR_MESSAGES,
                                      payload: {
                                        content: factor.details
                                      }
                                    });

                                    // Follow-up
                                    dispatch({
                                      type: SET_QUERY,
                                      payload: {
                                        query: `Interesting. Can you elaborate on preferences around ${factor.summary.toLowerCase()}?`
                                      }
                                    });

                                    // Navigate to agent
                                    history.push(`/admin/personas/chat`);
                                  }}
                                >
                                  Ask
                                </Button>
                              </CardFooter>
                            </CardBody>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="match_criteria">
                    <Row>
                      <Col xl="12">
                        { model.entity.match_criteria.map((criteria, index) => (
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-3" tag="h3">
                                <Row>
                                  <Col xl="8">
                                    <h4 className="mb-0">
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        {criteria.concept}
                                      </a>
                                    </h4>
                                    <p className="text-sm text-muted mb-0">{criteria.display}</p>
                                  </Col>
                                  <Col xl="3">
                                    <Progress
                                      className="progress-xs mt-2 mb-0"
                                      color="primary"
                                      max="100"
                                      value={criteria.value}
                                    />
                                  </Col>
                                  <Col xl="1">
                                    <div
                                      className="icon icon-shape bg-primary text-white rounded-circle shadow"
                                      style={{
                                        position: 'relative',
                                        right: 15,
                                        bottom: 10
                                      }}
                                    >
                                      <div style={{
                                        fontSize: 14
                                      }}>{criteria.value}%</div>
                                    </div>
                                  </Col>
                                </Row>
                              </CardTitle>
                              <CardText className="mb-0">
                                {criteria.details}
                              </CardText>
                            </CardBody>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="matched_customers">
                    <Row>
                      <Col xl="12">
                        { model.entity.matched_customers.slice(0, 3).map((customer, index) => (
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-3" tag="h3">
                                <Row>
                                  <Col xl="8">
                                    <h4 className="mb-0">
                                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        {customer.first_name} {customer.last_name}
                                      </a>
                                    </h4>
                                    <p className="text-sm text-muted mb-0">...</p>
                                  </Col>
                                  <Col xl="3">
                                    <Progress
                                      className="progress-xs mt-2 mb-0"
                                      color="primary"
                                      max="100"
                                      value={customer.match_likelihood}
                                    />
                                  </Col>
                                  <Col xl="1">
                                    <div
                                      className="icon icon-shape bg-primary text-white rounded-circle shadow"
                                      style={{
                                        position: 'relative',
                                        right: 15,
                                        bottom: 10
                                      }}
                                    >
                                      <div style={{
                                        fontSize: 14
                                      }}>{customer.match_likelihood}%</div>
                                    </div>
                                  </Col>
                                </Row>
                              </CardTitle>
                              <CardText className="mb-0">
                                {customer.match_reasoning}
                              </CardText>
                              <CardFooter>
                                <Button
                                  onClick={() => { selectCustomer(customer); }}
                                  color="primary" size="md" type="button"
                                  style={{
                                    width: 200,
                                    float: 'right'
                                  }}
                                >
                                  View Customer
                                </Button>
                              </CardFooter>
                            </CardBody>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewPersona = connect(state => ({
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(ViewPersona);

export default ConnectedViewPersona;



/*

<h5 className="h3">
                            {model.entity.profile.card.gender}
                            <span className="font-weight-light">, {model.entity.profile.card.age}</span>
                          </h5>
                          <div className="h5 font-weight-300">
                            {model.entity.profile.card.location}
                          </div>
                          <div className="h4 mt-4">
                            Job Function
                          </div>
                          <div className="h5 font-weight-300">
                            {model.entity.profile.card.job}
                          </div>

*/