/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Progress,
  Table,
  Container,
  Spinner,
  Row,
  Col
} from "reactstrap";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_PERSONAS
} from 'store/actions';

// Import report blocks
import PersonasChart from "./components/PersonasChart.js";
import RichInsights from "components/RichInsights";

// Query: Customer Activity by Persona
async function getPersonas(account) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get persona stats
  let personasQuery = {
    "events": [
      {
        "id": "Customer Activity",
        "math": "dau",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "custom_name": "Total Customer Created",
        "properties": [
            {
                "key": `properties.persona.version = '${account.active && account.active.models && account.active.models.personas ? account.active.models.personas.version : '0.0.6'}'`,
                "type": "hogql",
                "value": null
            },
            {
                "key": "clientId",
                "type": "event",
                "value": [
                    `${account.active.id}`
                ],
                "operator": "exact"
            }
        ]
      }
    ],
    "date_to": null,
    "display": "ActionsTable",
    "insight": "TRENDS",
    "interval": "month",
    "breakdown": "properties.persona.ref.id",
    "date_from": "all",
    "entity_type": "event",
    "breakdown_type": "hogql"
  }

  // Execute query
  const personasResponse = await axios.post(eventsApiUrl, personasQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Parse persona data
  let personaData = [];
  if(personasResponse.data && personasResponse.data.result.length > 0) {
    for(var i = 0; i < personasResponse.data.result.length; i++) {
      // Extract persona
      personaData.push({
        "id": personasResponse.data.result[i].breakdown_value,
        "total": personasResponse.data.result[i].aggregated_value
      });
    }
  }

  // Return values
  return {
    personas: personaData
  };
}

function CustomerAcquisition({ account, knowledge, insights, report, dispatch }) {
  // Get history
  const history = useHistory();

  // Sync customer insights
  useEffect(() => {
    // Load Customer Persona details
    if (account.initialized && !insights.initialized) {
      // Initialize insights
      dispatch({
        type: INIITIALIZE_INSIGHTS,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            },
            clients: {
              list: account.clients,
              selected: (account.clients.length > 0) ? account.clients[0] : null
            }
          }
        }
      });
    }

    // Sync Persona data
    (async () => {
      // Get customer details (all time)
      const personaData = await getPersonas(account);

      // Update Personas
      if(personaData && personaData.personas) {
        // Clone existing personas
        let existingPersonas = knowledge.entities;

        // Iterate over updated persona data
        let updatedPersonas = [];
        for(var i = 0; i < personaData.personas.length; i++) {
          // Find matching persona
          const matchedPersona = _.find(existingPersonas, function (existingPersona) {
            return existingPersona.id == personaData.personas[i].id;
          });

          // Update if matched
          if(matchedPersona) {
            // Update match count
            matchedPersona.matched = personaData.personas[i].total;

            // Add to new personas
            updatedPersonas.push(matchedPersona);
          }
        }

        // Update personas
        dispatch({
          type: SET_PERSONAS,
          payload: updatedPersonas
        });
      }
    })();
  }, [ account ]);

  // Select customer
  const selectCustomer = async (customer) => {
    console.log("Selected customer: ", customer);

    // Load customer data
    // firestore.collection("customers_v0.9").doc(customer.id).get()
    //   .then((doc) => {
    //     if(doc.exists) {
    //       // Update customer
    //       const customerData = doc.data();

    //       // Update local store
    //       dispatch({
    //         type: SET_ACTIVE_CUSTOMER,
    //         payload: customerData
    //       });

    //       // Got to review page
    //       console.log(customerData);
    //       history.push(`/admin/customers/insights/view/${customer.id}`);
    //     }
    //   })
  }

  return (
    <>
      <KnowledgeHeader
        headline="New Customer Acquisition"
        description="Analyze your new customer acquisition performance against your target personas."
      />
      <Container className="mt--6" fluid>
        <PersonasChart />
        <Row>
        <Col xl="4">
            <Card>
              <CardBody>
                <CardTitle className="pb-2 mb-3 border-bottom" tag="h3">
                  <span>Customer Summary</span>
                </CardTitle>
                {/* { insights.demographics.age ?
                  <Row>
                    <Col md="1"></Col>
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Age
                    </Label>
                    <Col md="6">
                      <Row className="col-form-label">
                        <div>{insights.demographics.age.toFixed(0)}</div>
                      </Row>
                    </Col>
                    <Col md="1"></Col>
                  </Row>
                  :
                  <></>
                } */}
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Gender
                  </Label>
                  <Col md="6">
                    <Row className="col-form-label">
                      <div>{insights.demographics.gender.female.toFixed(0)}% ∙ Female</div>
                    </Row>
                    <Row className="col-form-label">
                      <div>{insights.demographics.gender.male.toFixed(0)}% ∙ Male</div>
                    </Row>
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="bg-transparent">
                <Form>
                  <Select2
                    className="form-control"
                    defaultValue="1"
                    options={{
                      placeholder: "Select",
                    }}
                    data={[
                      { id: "1", text: "Top Converting Products" }
                    ]}
                  />
                </Form>
              </CardHeader>
              { insights.preferences && insights.preferences.length === 0 ? 
                <CardBody  style={{
                  minHeight: 350
                }}>
                  <Row style={{ marginTop: 150, flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Spinner color="primary" /> 
                  </Row>
                </CardBody>
                :
                <CardBody  style={{
                  minHeight: 350
                }}>
                  <ListGroup className="list my--3" flush>
                    { insights.preferences.map((concept, index) => (
                      <ListGroupItem className="px-0" key={index}>
                        <Row className="align-items-center">
                          <div className="col">
                            <h5>
                              <Badge color="primary" className="mr-2">
                                {concept.value ? concept.value.toFixed(2) : 0}%
                              </Badge>
                              {concept.display}
                            </h5>
                            <Progress
                              className="progress-xs mb-0"
                              color="primary"
                              max="100"
                              value={concept.value}
                            />
                          </div>
                          <Col className="col-auto">
                            <Button
                              color="primary" size="sm" type="button"
                              style={{
                                width: 100
                              }}
                              onClick={() => {
                                // View customer activity drill-down
                                //history.push(`/admin/customers/insights?persona=${model.entity.name}&filter=${dimension.display}`);
                              }}
                              disabled
                            >
                              View
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              }
            </Card>
          </Col>
          <Col xl="8">
            <Row>
              <Col xl="12">
                <Card
                  className="bg-gradient-primary border-0"
                  style={{
                    height: 140
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Ask a questions about these personas..."
                          style={{
                            zIndex: 100000000,
                            position: 'absolute',
                            width: '90%',
                            top: 5
                          }}
                          onChange={() => { console.log("changing...")}}
                          disabled
                        />
                        <div
                          className="icon icon-shape bg-white text-dark rounded-circle shadow"
                          style={{
                            zIndex: 200000000,
                            position: 'absolute',
                            right: '2%',
                            top: -2,
                            height: 80,
                            width: 80
                          }}
                        >
                          <i className="ni ni-books" />
                        </div>
                      </Col>
                      <Col
                        md="12"
                        style={{
                          paddingTop: 65
                        }}
                      >
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          top performers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          top performers
                        </Button>
                        <Button onClick={() => { console.log("selected customer"); }} color="default" size="sm" type="button">
                          new and noteworthy customers
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <RichInsights 
                  config={insights.richData}
                  //persona={insights.selectedPersona}
                  title="Customer Segment Analysis"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        
      </Container>
    </>
  );
}

// Connect to store
const ConnectedCustomerAcquisition = connect(state => ({
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report
}))(CustomerAcquisition);

export default ConnectedCustomerAcquisition;
